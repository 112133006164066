/*
 * Copyright 2019-2023 Bloomreach
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * A reference to an entity within the page model.
 */
export interface Reference {
  $ref: string;
}

/**
 * Checks whether a value is a reference.
 * @param value The value to check.
 */
export function isReference(value: any): value is Reference {
  return !!value?.$ref;
}

export function resolve<T>(object: Record<string, any>, reference: Reference): T | undefined {
  return reference.$ref.split('/').reduce((value, key) => (key ? value?.[key] : object), object) as T;
}
