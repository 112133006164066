/*
 * Copyright 2021-2023 Bloomreach
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { HttpRequest } from '../spa/http';
import { Cookie } from './cookie';

export class Segmentation {
  static readonly SEGMENT_IDS_PARAMETER = '__br__segment_ids';

  /**
   * Get the segmentIds from cookie
   * @return string
   */
  public static GET_SEGMENT_IDS(request?: HttpRequest): string {
    const cookie = request?.headers ? Cookie.GET_COOKIE_FROM_REQUEST(request) : Cookie.GET_COOKIE();

    return cookie[this.SEGMENT_IDS_PARAMETER] ?? '';
  }
}
