/*
 * Copyright 2019-2023 Bloomreach
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { MetaImpl, Meta } from './meta';

const HTML_COMMENT = /^<!--(.*)-->$/;

export type MetaComment = Meta;

/**
 * Meta information stored in HST-comments.
 */
export class MetaCommentImpl extends MetaImpl implements MetaComment {
  getData(): string {
    const data = super.getData();
    const [, payload = data] = data.match(HTML_COMMENT) || [];

    return payload;
  }
}

/**
 * Checks whether a value is a meta-data comment.
 * @param value The value to check.
 */
export function isMetaComment(value: any): value is MetaComment {
  return value instanceof MetaCommentImpl;
}
